@import "App.css";
.border-dashed {
  border-style: dashed;
}

.first-navbar-menu {
  background: #3E4957;
  z-index: 9999
}

//.navbar .dropdown-menu
#root {
  //padding-top: 56px;
}

.fs-7 {
  font-size: 14px !important;
}

.fs-8 {
  font-size: 18px !important;
}
.bg-success-light{
  background-color: #4CAF50 !important;
}
.text-dark-gray{
  color: #3E4957 !important;
}
.text-light-gray{
  color: #8B929A !important;
}
.nav-item.active {
  background: #353F4B;
}
.nav-link.disabled {
  color: #505E70 !important;
}

.absolute-edit-block {
  z-index: 1050;
}

.vl {
  height: 60%;
  width: 1px;
  margin: auto;
}

.isMobilePreview .absolute-mobile-edit-block {
  top: 0;
}
//.isMobilePreview{
//  padding-top: 56px;
//}
.absolute-mobile-edit-block {
  height: 56px;
  z-index: 1031;
  //top: 50px;
  left: 0;
}

.btn-outline-primary-plus {
  --bs-btn-color: #ffffff !important;
  --bs-btn-bg: #1061e7 !important;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #f1ecec;
  --bs-btn-hover-bg: #054198;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.icon-size {
  font-size: 19px;
}

.top-fake-block {
  height: 56px;
}
.element-block:hover {
  border-color: #0d6efd!important;
}

.element-img:hover {
  filter: invert(81%) sepia(7%) saturate(1094%) hue-rotate(190deg) brightness(375%) contrast(97%);
}

.color-picker-input{
  z-index: 9999;
  //@media screen and (min-width: 769px){
  top: -200px;
  //}
  //@media screen and (max-width: 768px){
  //  bottom: -10%;
  //}
  .input-group .btn{
    border-color: #DDE0E4!important;
  }
  .input-group .btn:hover {
    background: #ffffff!important;
    color: #3A83F6!important;
  }
  .input-group .btn:focus {
    background: #ffffff!important;
    color: #3A83F6!important;
    box-shadow: none!important;
  }
  .input-group .btn:active {
    background: #ffffff!important;
    color: #3A83F6!important;
  }
}

.top-spacing{
  top: 60px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  //text-align: start;
}

.bottom-spacing {
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  //text-align: center;
}

.right-spacing{
  top: 124px;
  right: 10px;
}

.left-spacing {
  top: 124px;
  left: 10px;
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown-node {
  display: inline-block;
  cursor: pointer;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: -100px;
  z-index: 1000;
  display: block;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.custom-dropdown-menu > li > a {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

.custom-dropdown-menu > li > a:hover,
.custom-dropdown-menu > li > a:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.custom-dropdown-menu > .active > a,
.custom-dropdown-menu > .active > a:hover,
.custom-dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
  outline: 0;
}

.custom-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.icon-dropdown{
  .dropdown-toggle::after {
    margin-left: 3.255em!important;
  }
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1!important;
}

.dark-line {
  border: 1px solid #505E70;
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-title {
  font-size: .9em;
}

@media (max-width: 576px) {
  .z-index-value{
    z-index: 11!important;
  }

}


@media (min-width: 576px) {
  .hidden-desktop {
    display: none !important;
  }

  .hidden-opacity-desktop {
    position: relative !important;
  }

  .hidden-opacity-desktop > * {
    opacity: 0.2 !important;
  }

  .hidden-opacity-desktop::after {
    content: '';
    background-image: url("../src/assets/images/icon.svg");
    min-width: 100%;
    min-height:100%;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    max-height: 100%;
    max-width:100%;
    background-size: 100% 100%;
  }
}

@media (max-width: 576px) {
  .hidden-mobile {
    display: none !important;
  }

  .hidden-opacity-mobile {
    position: relative !important;
  }
  .hidden-opacity-mobile > * {
    opacity: 0.2 !important;
  }
  .hidden-opacity-mobile::after {
    content: url("../src/assets/images/icon.svg");
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}


$navbar-light-color: white;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
$primary: #3A83F6;
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "white": $white,
        "gray":#505E70,
        "blue": #3A83F6,
        "light-blue": #8BADE2,
        "btn-img-color": #F2F7FF,
        "dark-gray": #3E4957,
        "background-mobile": #8B929A,
        "border-gray": #DDE0E4,
        "main-background": #F5F5F5,
        "qr-code-background": #818892,
        "size-gray-text": #79899E,
        "secondary-light": #F3F6FD
);
$headings-font-weight: 700;
$font-family-sans-serif: 'Arial';

.first-navbar-menu .dropdown-menu {
  --#{$prefix}dropdown-bg: #3E4957;
  --#{$prefix}dropdown-link-color: white;
  --#{$prefix}dropdown-link-hover-color: white;
  --#{$prefix}dropdown-link-hover-bg: #353F4B;
  //--#{$prefix}dropdown-link-active-color:
}

.first-navbar-menu .nav-item:hover .nav-link:not(.disabled) {
  background: #353F4B;
  $nav-link-hover-color: white;
}


@import "bootstrap";
.btn-primary {
  @include button-variant($primary, $primary, $white);
}

.blue-hover {
  border: 1px solid #DDE0E4 !important;
}

.blue-hover:hover {
  border: 1px solid cornflowerblue !important;
}

.nav .nav-link.active {
  background: transparent;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: $primary;
  color: $primary !important;
}

.modal-body {
  .nav-link {
      color: #3E4957;
  }
}

.accordion-button-add-modal {
  font-size: 20px;
  color: #3E4957;

  @media (max-width:991px) {
    font-size: 18px;
  }
}

.accordion-button-add-modal.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  background-color: $white !important;
  
}


.accordion-button-add-modal.accordion-button:focus {
  border-color: $white !important;
  box-shadow: $white !important;
}

//.accordion {
//  --bs-accordion-btn-focus-border-color: $white !important;
//}

.first-navbar-menu .navbar-nav {
  --bs-nav-link-hover-color: #FFFFFF !important;
  --bs-nav-link-disabled-color: #505E70 !important;

}

.fixed-top:not(.first-navbar-menu) {
  margin-top: 56px;
}

.empty-element::after {
  content:"Add your custom text"
}

.cursor-pointer {
  cursor:pointer;
}
.active-icon {
  border-color: #DDE0E4;
  --bs-btn-hover-border-color: #DDE0E4;
  --bs-btn-color: #3E4957;
  --bs-btn-hover-bg: #FBFBFB;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.container-scroll {
  height: 230px;
  //overflow-x: hidden;
  overflow-y: auto;

  .select-icon-item {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    //@media (max-width: 450px) {top: 51%;}
  }

  @media (max-width: 450px) {
    .icon-responsive {
      font-size: 1.75rem;
    }
  }
}

.container-scroll::-webkit-scrollbar {
  width: 1px; /* Установите желаемую ширину ползунка */
}

.container-scroll::-webkit-scrollbar-thumb {
  background-color: transparent; /* Сделать ползунок невидимым */
}

.container-scroll::-webkit-scrollbar-track {
  background-color: transparent; /* Сделать фон прокрутки невидимым */
}

.loop-icon {
  left: -40px;
}

.cart-circle-div {
  width:64px;
  height:64px;
  top:66px;
  right:10px;
  border: 1px solid rgba(12, 12, 12, 0);
  transition: border 0.5s ease-in-out;
}

.cart-circle-div:hover {
  border: 1px solid rgba(128, 128, 128, 0.42)
}

@media (max-width:576px) {
  .MuiTreeView-root {
    flex: 0 0 150px !important;
  }
  .first-navbar-menu {
    height: 56px;
  }
}

.solid-blue {
  cursor: pointer !important;
  border: 1px #3A83F6 !important;
  border-style: solid !important;
}

.dashed-grey {
  cursor: pointer !important;
  border: 1px #DDE0E4;
  border-style: dashed !important;
}

.dashed-blue {
  cursor: pointer !important;
  border: 1px #3A83F6;
  border-style: dashed !important;
}

.tooltip-custom {
  --bs-tooltip-bg: #1F3354 !important;
}

.active-blue-button {
  --bs-btn-active-color: #3A83F6 !important;
  --bs-btn-hover-color: #8B929A !important;
  --bs-btn-color: #8B929A !important
}

@media (max-width:479px) {
  .tox-tiered-menu div:nth-child(1) {
    left: 0 !important;
    right: 0 !important;
  }
  .tox-menu {
    width: 175px !important
  }
}

//.secondary-block-button > * {
//  transition: ease-in-out 500ms
//}

.secondary-block-button {
  & div {
    height: 1px;
    background-color: rgba(207, 207, 207, .5);
  }

  & span {
    border: 1px solid rgba(207, 207, 207, .5)
  }
}

.secondary-block-button:hover div {
  background-color: #3A83F6 !important;
}

.element-title {
  @media (max-width:991px) {
    font-size: 14px;
  }
}
.btn-trash{
  &:hover, &:active{
    background: inherit !important;
    color: inherit;
    text-decoration: inherit;
    border-color: inherit;
  }
  i{
    color: #6c757d;
  }
  border-color: #DDE0E4 !important;
  cursor: pointer;
}
.form-label{
  z-index: 0 !important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.date-picker{
  padding-top: 1.725rem;
}
.date-label{
  padding-top: 0.5rem !important;
  font-size: 14px;
}
.title-position{
  @media (max-width: 1200px) {
    position: relative;
    left: 60px;
  }
}
.text-dark-custom {
  color: #212529;
}
.text-gray-custom{
  color: #8B929A;
}
.light-blue{
  color: #8EB8FA;
}
.description-responsive{
  width: 402px;
  @media (max-width: 767px) {
    width: 305px;
  }
}
.white-hover {
  transition: all ease-in-out 0.3s;
  &:hover {
    color: $white !important;
  }
}
.p-12 {
  padding: 12px;
}
.publishBtnModal {
  width: 234px;

  @media (max-width: 767px) {
    width: 100%;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.icon-settings-btn {
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-color: #0d6efd;
}