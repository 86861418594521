.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.blue-hover:hover {
    border: 1px solid cornflowerblue !important;
}

.user-template-container {
    height: 339px;
    width: 262px;
    padding: 16px;
    position: relative;
}
@media (max-width: 456px) {
    .user-template-container {
        height: 230px;
        width: 130px;
        padding: 8px;
        /*margin-right: 3px;*/
        position: relative;
    }
}

.user-template-cross {
    width: 34px;
    height: 34px;
    cursor: pointer;
}

.user-template-sub-container {
    box-shadow: 0 4px 15px 0 #AEAEAE40;
    border-radius: 4px;
    max-width: 100%;
    max-height: 100%;
    margin: auto 0
}

#collapseTwo {
    max-height: 500px !important;
    overflow-y: scroll;

}

.saved-blocks-scroll::-webkit-scrollbar {
    width: 7px;
    height: 10px;
}

.saved-blocks-scroll::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.saved-blocks-scroll::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
    border-radius: 20px;
}

.saved-blocks::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari и Chrome */
}